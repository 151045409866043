import { Action, action } from 'easy-peasy'
import { Profile } from 'Types'

export interface State {
  profile: Profile
  profilePicture: any
  is_student: boolean
  has_applications: boolean
}
export interface Actions {
  updateProfile: Action<this, Profile>
  updateProfilePicture: Action<this, any>
  setIsStudent: Action<this, boolean>
  setHasApplications: Action<this, boolean>
}
export interface Thunks {
  // updateCounterThunk: Thunk<this, number>
}

export interface ProfileModel extends State, Actions, Thunks {}

export const profileModel: ProfileModel = {
  profile: {
    department: '',
    display_name: '',
    email: '',
    first_name: '',
    initials: '',
    kerberos: '',
    kerberos_id: '',
    krb_name: '',
    last_name: '',
    mit_id: '',
    mitid: '',
    office_location: '',
    phone_number: '',
    source: ''
  },
  profilePicture: null,
  is_student: false,
  has_applications: false,

  // ACTIONS
  updateProfile: action((state, payload) => {
    state.profile = payload
  }),
  updateProfilePicture: action((state, payload) => {
    state.profilePicture = payload
  }),
  setIsStudent: action((state, payload) => {
    state.is_student = payload
  }),
  setHasApplications: action((state, payload) => {
    state.has_applications = payload
  })

  // // THUNKS
  // updateCounterThunk: thunk(async (actions, payload) => {
  // 	setTimeout(() => actions.updateCounter(payload), 1000)
  // })
}

import { CategoryCardList, Text, TextType } from '@mit/hui'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { OpportunityController } from 'Api/controller'
import { getCardItems } from 'Common/Opportunity'
import { Opportunity } from 'Types'
import { useStoreActions } from 'Store'

interface Props {}

const controller = new OpportunityController()

export const MyApplications: React.FC<RouteComponentProps<Props>> = ({ match, history }) => {
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setWrapperClass } = useStoreActions(state => state.globalModel)

  useEffect(() => {
    setIsLoading(true)
    controller
      .getOpportunities(true)
      .then(data => {
        setIsLoading(false)
        setOpportunities(data)
      })
      .catch(error => {
        setIsLoading(false)
      })
    setWrapperClass('my-applications')
    return () => {
      setWrapperClass(undefined)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div className='master-detail'>
      {/* <div className='master'></div> */}
      <div className='detail'>
        <div className='header'>
          <Text type={TextType.Heading4} content='My Applications' />

          <div className='container-fluid'>
            <div className='d-flex opportunity-list-header'>
              {!!opportunities.length && <Text content={`${opportunities.length} application${opportunities.length > 1 ? 's' : ''}`} />}
            </div>
          </div>
          <div className='detail-contents' role='region' aria-label='detail-content'>
            <div className='container-fluid'>
              <div className=' row no-gutters align-self-stretch h-100 mh-100'>
                <div className='col-12 py-4'>
                  <div className={`${!isLoading ? 'category-cards opportunities-list' : ''}`}>
                    {!!opportunities.length || isLoading ? (
                      <CategoryCardList addWrapper={false} items={getCardItems(opportunities, history)} isLoading={isLoading} />
                    ) : (
                      <Text type={TextType.Heading3} content='No Applications' />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// eslint-disable
import appConfig from '../../app-config'
import { RegionalConfig } from 'TemplateConstants/types'
import { ActiveRegionResponse, AtlasConfig } from '@mit/atlas-config/dist.browser'
import { getStage } from 'TemplateConstants/common'
import { AuthProvider } from './AuthProvider'
import { ThunkCreator } from 'easy-peasy'

export const getActiveRegionConfig = (): RegionalConfig => {
  const regionConfig: ActiveRegionResponse = JSON.parse(sessionStorage.getItem('ActiveRegion') as string)
  if (regionConfig === null) {
    console.error('No Regional Config Loaded. Default to Primary Region')
    return (appConfig.regions[0] as Record<string, any>) as RegionalConfig
  }

  const isPrimaryRegion: boolean = regionConfig.activeRegion === 'primary'

  // eslint-disable-next-line
  let config =
    appConfig.regions.length > 1
      ? appConfig.regions.filter(region => (isPrimaryRegion ? region.isPrimaryRegion : !region.isPrimaryRegion))[0]
      : appConfig.regions[0]

  // escape for apps that have only primary region config
  if (appConfig.regions.length === 1 && !isPrimaryRegion) {
    return (config as Record<string, any>) as RegionalConfig
  }

  config.amplify.oauth.domain = regionConfig.authenticationUrl.replace('https://', '')
  config.api.domain = regionConfig.apiUrl.replace('https://', '')
  return (config as Record<string, any>) as RegionalConfig
}

export const initializeActiveRegion = async (
  checkRegionUpdate: ThunkCreator<{ config?: ActiveRegionResponse | undefined; hasHttpError?: boolean | undefined }, any>
): Promise<void> => {
  const authProvider = new AuthProvider()
  const config: ActiveRegionResponse = await getActiveRegion()
  const activeRegion = JSON.stringify(config)
  const previousActiveRegion = sessionStorage.getItem('ActiveRegion')

  console.info('RegionConfig', config)
  sessionStorage.setItem('ActiveRegion', activeRegion)
  await authProvider.configure()
  if (previousActiveRegion !== activeRegion) {
    // active region has changed, check to see if first time user loading and primary region is active
    if (previousActiveRegion === null && config.activeRegion === 'primary') {
      console.info('First load, primary region active')
    } else {
      console.log('Active Region has changed, refreshing authorization in different region')
      await authProvider.refreshToken()
    }
  }

  await authProvider.getToken(true)

  checkRegionUpdate({ config })
}

export const getActiveRegion = async (): Promise<ActiveRegionResponse> => {
  // const config: ActiveRegionResponse = await AtlasConfig.forStage(getStage()).getActiveRegion()
  const config: ActiveRegionResponse = await AtlasConfig.forRuntime()
    .stage(getStage())
    .queryWithHttp()
    .build()
    .queryActiveRegion()
  return config
}

import cloneDeep from 'clone-deep'

const defaultFilter = {
  search_string: '',
  focus_areas: [],
  terms: [],
  remote: false,
  states: [],
  local_cities: [],
  international_cities: [],
  countries: [],
  themes: [],
  deadline: null,
  faculty_sponsors: [],
  departments: [],
  hours: [],
  compensation: [],
  funding_source: [],
  degree_level: [],
  class_year: [],
  major: []
}

export const getDefualtFilters = (): any => {
  return cloneDeep(defaultFilter)
}

import { Text, TextType } from '@mit/hui'
import React, { useState } from 'react'
import { Opportunity } from 'Types'
import { SaveFilter } from '../filter/SaveFilter'
import { BannerMessage } from '../form/BannerMessage'

interface Props {
  opportunities: Opportunity[]
}

export const OpportunityListHeader: React.FC<Props> = ({ opportunities }) => {
  const [showBanner, setShowBanner] = useState<boolean>(false)

  const onClick = () => {
    setShowBanner(true)
    setTimeout(() => {
      setShowBanner(false)
    }, 3000)
  }

  return (
    <>
      {/* <Alert
				type='info'
				text='Welcome to ELx, your gateway to Experiential Learning Opportunities (ELOs) at MIT including UROP, MISTI, and more! Find and apply for important, real-world projects in MIT labs and makerspaces, start-ups, non-profits, K-12 schools, and global industries.'
			/> */}
      <br />
      <div className='opportunity-list-header'>
        <Text content='Welcome to ELx!' type={TextType.Heading2} />
        <h5 className='intro-text'>
          ELx is your gateway to Experiential Learning Opportunities (ELOs) at MIT including UROP, MISTI, and more! Find and apply for important,
          real-world projects in MIT labs and makerspaces, start-ups, non-profits, K-12 schools, and global industries.
        </h5>
      </div>
      <div className='d-flex opportunity-list-header'>
        <Text type={TextType.Heading4} content={'Experience Options'} padded={false} />
      </div>
      {showBanner && <BannerMessage banner={{ type: 'success', text: 'Preferences successfully updated' }} />}
      <div className='d-flex opportunity-list-header' style={{ minHeight: '2rem' }}>
        <div className='mr-3'>
          <Text content={`${opportunities.length} experience${opportunities.length !== 1 ? 's' : ''}`} padded={false} />
        </div>
        <SaveFilter onClick={onClick} />
      </div>
    </>
  )
}

import { Map } from '@mit/hui'
import React from 'react'
import appConfig from '../../app-config'
import { getDateString } from 'Common'
import { getUrl } from 'Common/Opportunity'
import { Opportunity } from 'Types'

interface Props {
  opportunity: Opportunity
}

export const ExperienceDetailInfoText: React.FC<Props> = ({ opportunity }) => {
  // const advisor = {
  // 	name: opportunity?.sponsor.text,
  // 	contact: opportunity?.contact_email
  // }

  const dates =
    opportunity?.opportunity_start_date || opportunity?.opportunity_end_date
      ? {
          startDate: opportunity.opportunity_start_date,
          endDate: opportunity.opportunity_end_date
        }
      : null

  const latitude = opportunity?.location?.coordinates?.lat?.toString() || undefined
  const longitude = opportunity?.location?.coordinates?.lng?.toString() || undefined

  const types = !!opportunity?.primary_theme ? [...(opportunity.themes || []), opportunity.primary_theme] : opportunity?.themes

  const mapBoxKey = appConfig.regions[0].mapBoxKey

  return (
    <>
      {!!types && !!types.length && (
        <div className='sidebar-info' style={{ marginBottom: '1rem' }}>
          <p style={{ marginBottom: 0 }}>Type</p>
          <ul>
            {types.map((row: any) => (
              <li>
                <p style={{ marginTop: 0, marginBottom: 0 }}>{row?.text}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!opportunity?.terms && opportunity?.terms?.length > 0 && (
        <div className='sidebar-info'>
          <p style={{ marginBottom: 0 }}>Terms</p>
          <ul>
            {opportunity?.terms.map((row: any) => (
              <li>
                <p style={{ marginTop: 0, marginBottom: 0 }}>{row?.text}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!opportunity?.hours_per_week?.text && (
        <div className='sidebar-info'>
          <p style={{ marginBottom: 0 }}>Estimated Hours Per Week</p>
          <ul>
            <li>
              <p style={{ marginTop: 0 }}>{opportunity?.hours_per_week?.text}</p>
            </li>
          </ul>
        </div>
      )}
      {!!opportunity?.sponsor && opportunity?.sponsor?.id > 0 && (
        <div className='sidebar-info'>
          <p style={{ marginBottom: 0 }}>PI/Faculty Sponsor/Program Director</p>
          <ul>
            <li>
              <p style={{ marginTop: 0 }}>
                {opportunity?.sponsor?.text} ({opportunity?.sponsor?.krb_name}@mit.edu)
              </p>
            </li>
          </ul>
          {/* <p style={{ marginTop: 0 }}>{advisor.contact}</p> */}
        </div>
      )}

      <div className='sidebar-info'>
        <p style={{ marginBottom: 0 }}>Contact</p>
        <ul>
          {!!opportunity?.contact && opportunity?.contact?.id > 0 && (
            <li>
              <p style={{ marginTop: 0, marginBottom: 0 }}>
                {opportunity?.contact?.text} ({opportunity?.contact?.krb_name}@mit.edu)
              </p>
            </li>
          )}
          {opportunity?.contact_email && (
            <li>
              <p style={{ marginTop: 0, marginBottom: 0 }}>{opportunity?.contact_email}</p>
            </li>
          )}
        </ul>
      </div>

      {opportunity?.department?.text && (
        <div className='sidebar-info'>
          <p style={{ marginBottom: 0 }}>Department/Program</p>
          <ul>
            <li>
              <p style={{ marginTop: 0 }}>{opportunity?.department?.text}</p>
            </li>
          </ul>
        </div>
      )}
      {opportunity?.texts?.website_url && (
        <div className='sidebar-info'>
          <p style={{ marginBottom: 0 }}>Website</p>
          <ul>
            <li>
              <p style={{ marginTop: 0 }}>
                <a href={getUrl(opportunity?.texts?.website_url)} target='_blank' rel='noopener noreferrer' className='website-link'>
                  {getUrl(opportunity?.texts?.website_url)}
                </a>
              </p>
            </li>
          </ul>
        </div>
      )}
      {dates && (
        <div className='sidebar-info'>
          <p style={{ marginBottom: 0 }}>Student Participation Dates</p>
          <ul>
            <li>
              <p style={{ marginTop: 0 }}>{`${getDateString(dates.startDate || '', false) || 'TBD'} - ${getDateString(dates.endDate || '', false) ||
                'TBD'}`}</p>
            </li>
          </ul>
        </div>
      )}
      {!!opportunity?.deadline_date && (
        <div className='sidebar-info'>
          <p style={{ marginBottom: 0 }}>Application deadline</p>
          <ul>
            <li>
              <p style={{ marginTop: 0 }}>{getDateString(opportunity?.deadline_date || '')}</p>
            </li>
          </ul>
        </div>
      )}
      {/* {!!opportunity?.funding.text && (
				<div className='sidebar-info'>
					<p style={{ marginBottom: 0 }}>Funding</p>
					<p style={{ marginTop: 0 }}>{opportunity?.funding.text}</p>
				</div>
			)} */}
      {!!opportunity?.payment && opportunity?.payment?.filter(row => row.selected)?.length > 0 && (
        <div className='sidebar-info' style={{ marginBottom: '1rem' }}>
          <p style={{ marginBottom: 0 }}>Compensation</p>
          <ul>
            {opportunity?.payment
              .filter(row => row.selected)
              .map(row => (
                <li>
                  <p style={{ marginTop: 0, marginBottom: 0 }}>
                    {row.title}: {row.value}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      )}
      {!!opportunity?.work_authorization_required && (
        <div className='sidebar-info'>
          <span></span>
          <ul>
            <li>
              <p style={{ marginBottom: '1rem' }}>USA work authorization is required</p>
            </li>
          </ul>
        </div>
      )}

      <div className='sidebar-info'>
        <p style={{ marginBottom: 0 }}>Location</p>
        <ul>
          <li>
            <p style={{ marginTop: 0 }}>{opportunity?.location?.text || 'Remote'}</p>
          </li>
        </ul>
      </div>
      {latitude && longitude && <Map accessToken={mapBoxKey} latitude={latitude} longitude={longitude} zoom={7} scrollZoom={true} />}
    </>
  )
}

import React, { useEffect } from 'react'
import { Loader } from '@mit/hui'
import { initializeActiveRegion } from 'TemplateConstants/authorization'
import { useStoreActions, useStoreState } from 'Store'
import { AppInitializer } from 'Components/app'
import { SessionExpirationModal } from './SessionExpirationModal'
import { LoaderProps } from '@mit/hui/build/components/Loader'
import App from 'Components/app/App'
import useImagePreloader from 'Hooks/PreloadImage'

interface Props {
  loaderProps: LoaderProps
}

export const Initialization: React.FC<Props> = props => {
  const { setHasAccess, setIsLoading } = useStoreActions(action => action.LoaderModel)
  const { hasAccess, isLoading } = useStoreState(state => state.LoaderModel)
  const { activeRegionInitialized, hasOutage } = useStoreState(state => state.RegionModel)
  const { checkRegionUpdate } = useStoreActions(action => action.RegionModel)
  useImagePreloader(['/ELO-brand-logo.jpg'])

  useEffect(() => {
    initializeActiveRegion(checkRegionUpdate)
      .then(_ => {})
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        setHasAccess(false)
      })

    // eslint-disable-next-line
  }, [])

  if (isLoading || !hasAccess || hasOutage) {
    return (
      <>
        {activeRegionInitialized && <AppInitializer />}
        <Loader
          {...props.loaderProps}
          hasAccess={hasAccess}
          isLoading={isLoading}
          type={hasOutage ? 'outage' : hasAccess || isLoading ? 'default' : 'noAccess'}
        />
      </>
    )
  }

  return (
    <>
      <SessionExpirationModal />
      <App />
    </>
  )
}

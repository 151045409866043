import React from 'react'
import { Opportunity } from 'Types'
import { useStoreState } from 'Store'
import { UropAlert } from 'Components/alert/UropAlert'

interface Props {
  opportunity: Opportunity
  title: string
  isLoading: boolean
}

export const ExperienceDetailHeader: React.FC<Props> = ({ opportunity, title, isLoading }) => {
  const { lookupItems } = useStoreState(state => state.filterModel)

  if (isLoading) return null

  return (
    <>
      <br />
      <UropAlert
        theme_id={((opportunity?.primary_theme?.id as unknown) as number) ?? 0}
        sponsor_funded_deadline={lookupItems?.urop_deadlines?.sponsor_funded_deadline ?? ''}
        urop_funded_deadline={lookupItems?.urop_deadlines?.urop_funded_deadline ?? ''}
        term={lookupItems?.urop_deadlines?.term ?? ''}
      />

      <div className='opportunity-list-header'>
        <h4>{title}</h4>
      </div>
    </>
  )
}

import React, { Fragment } from 'react'
import { Opportunity } from 'Types'
import { CategoryCardList, MobileView, Button, ButtonType } from '@mit/hui'
import { History } from 'history'
import { getCardItems } from 'Common/Opportunity'
import { useStoreActions } from 'Store'

interface OpportunitiesProps {
  isLoading: boolean
  opportunities: Opportunity[]
  openFilter?: any
  history: History
}

export const OpportunitiesList: React.FC<OpportunitiesProps> = ({ openFilter, history, isLoading, opportunities }) => {
  const { setMobileTempOpportunityQuery } = useStoreActions(state => state.filterModel)
  return (
    <Fragment>
      <div className='filter-btn'>
        <Button
          text='Filter'
          onClick={() => {
            setMobileTempOpportunityQuery()
            openFilter(MobileView.Master)
          }}
          type={ButtonType.Small}
          icon='filter'
        />
      </div>
      <div aria-busy={isLoading ?? false} className={`${!isLoading ? 'category-cards opportunities-list' : ''}`}>
        <CategoryCardList isLoading={isLoading} addWrapper={false} items={getCardItems(opportunities, history)} />
      </div>
    </Fragment>
  )
}

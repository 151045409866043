import { AccordionItem, AutoComplete, CalendarInput, Icon, ListItemProps, Textbox } from '@mit/hui'
import { getDefualtFilters } from 'Api/mock/Filter'
import { useStoreActions, useStoreState } from 'Store'
import { Lookups, OpportunityFilter } from 'Types'
import { History } from 'history'
import React, { Fragment, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CheckboxGroup } from '../form'

interface IFilterFormProps {
  filter: OpportunityFilter
  lookupItems?: Lookups
  history: History
}
const getKeyValue = <T extends object, U extends keyof T>(key: U) => (obj: T) => obj[key]

export const FilterFormItem: React.FC<IFilterFormProps> = ({ filter, lookupItems, history }) => {
  let id = uuidv4()
  const [userOpened, setUserOpened] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const { opportunityQuery, filtersCleared } = useStoreState(state => state.filterModel)
  const { setOpportunityQuery, setFilterCleared } = useStoreActions(state => state.filterModel)

  useEffect(() => {
    if (filtersCleared) {
      setUserOpened(false)
    }
  }, [filtersCleared])

  const onChange = (filter_array_key: string, item: any, value?: boolean | string) => {
    setFilterCleared(false)
    setUserOpened(true)
    if (!filter_array_key.length) {
      filter_array_key = item.filter_array_key
    }

    let filters: any = { ...opportunityQuery },
      filterObject = filters[filter_array_key],
      defaultFilterObject = getDefualtFilters()[filter_array_key]

    if (filterObject === undefined) {
      filterObject = defaultFilterObject
    }

    // debugger
    console.error(filter_array_key, item, value)
    if (Array.isArray(value)) {
      filterObject = value
    } else if (typeof filterObject === 'boolean' || typeof filterObject === 'string') {
      filterObject = value
    } else {
      if (value) {
        if (!filterObject) {
          filterObject = value
        } else {
          filterObject.push(item)
        }
      } else {
        filterObject = filterObject.filter((row: any) => row.id !== item.id)
      }
    }

    filters[filter_array_key] = filterObject
    setOpportunityQuery(filters)
  }

  const getAccordionState = (checkFilter: OpportunityFilter = filter) => {
    if (filtersCleared) {
      return 0
    }
    if (userOpened) {
      return 1
    }

    // let totalReturn = 0
    // if (!!checkFilter.items) {
    // 	// @ts-ignore
    // 	checkFilter?.items?.forEach(item => {
    // 		totalReturn = (getAccordionState(item) || 0) + totalReturn
    // 	})
    // }
    // if (totalReturn > 0) return 1

    const items = getKeyValue<any, any>(checkFilter.lookup_array_key)(lookupItems)
    if (items) {
      const filter_array_key = checkFilter.filter_array_key || 'forceNegativeMatch'
      if (opportunityQuery.hasOwnProperty(filter_array_key)) {
        // @ts-ignore
        if (opportunityQuery[filter_array_key].length) {
          return 1
        } else {
          return 0
        }
      } else return 0
    } else {
      if (!checkFilter.items) {
        const filter_array_key = checkFilter.filter_array_key || checkFilter.title?.split('/')[0].toLowerCase() || 'forceNegativeMatch'
        if (opportunityQuery.hasOwnProperty(filter_array_key)) {
          // @ts-ignore
          return opportunityQuery[filter_array_key] ? 1 : 0
        }
        return 0
      }
      let totalReturn = 0
      // @ts-ignore
      checkFilter.items.forEach(item => {
        totalReturn = (getAccordionState(item) || 0) + totalReturn
      })
      return totalReturn > 0 ? 1 : 0
    }
  }

  const getAccordionItems = () => {
    if (lookupItems !== undefined && !!filter.lookup_array_key) {
      let items = getKeyValue<any, any>(filter.lookup_array_key)(lookupItems)
      if (items) {
        // let returnItems: ListItemProps[] = items.map((it: any) => {
        // 	return {
        // 		text: it.text,
        // 		icon: '',
        // 		template: () => {
        // 			return getFormComponent(it.component_type || 'checkboxgroup', it)
        // 		}
        // 	}
        // })
        let returnItems = [
          {
            text: 'test',
            icon: '',
            template: () => <CheckboxGroup items={items} filter={filter} onChange={onChange} getFilterValue={getFilterValue} />
          }
        ]
        return returnItems
      }
    } else if (filter.items) {
      let returnItems: ListItemProps[] = filter.items.map((it: any) => {
        let newItem: ListItemProps
        if (it.component_type && it.component_type !== 'checkbox') {
          newItem = {
            text: it.title,
            icon: it.icon,
            template: () => <FilterFormItem filter={it} lookupItems={lookupItems} history={history} />
          }
        } else {
          newItem = {
            text: it.text,
            icon: '',
            template: () => {
              return getFormComponent(it.component_type || 'checkboxgroup', it)
            }
          }
        }
        return newItem
      })
      return returnItems
    }

    return [
      {
        text: '',
        icon: '',
        template: () => {
          return getFormComponent(filter.component_type || 'checkboxgroup', filter)
        }
      }
    ]
  }

  const getAutocompleteOptions = () => {
    if (!!Object.keys(lookupItems || {}).length && filter.lookup_array_key !== undefined) {
      let items = getKeyValue<any, any>(filter.lookup_array_key)(lookupItems)
      let returnItems: ListItemProps[] = items.map((it: any) => {
        return { id: it.id, text: it.text, icon: '' }
      })
      return returnItems
    }
    return []
  }

  const getFilterValue = (component_type: string, item: any): any => {
    if (filtersCleared) {
      return component_type === 'checkbox' || component_type === 'checkboxgroup' ? false : null
    }
    let filters: any = { ...opportunityQuery }

    let filterValues = filters[filter.filter_array_key || '']
    if (!filterValues) {
      filterValues = filters[item.filter_array_key || '']
    }
    // debugger

    if (Array.isArray(filterValues)) {
      if (component_type === 'autocomplete') return filterValues

      if (filterValues.length) {
        let value = filterValues.find((row: any) => row.id === item.id)
        if (value) return value
        return component_type === 'checkbox' || component_type === 'checkboxgroup' ? false : null
      }
    } else if (typeof filterValues === 'boolean' || typeof filterValues === 'string') {
      return filterValues
    }

    return component_type === 'checkbox' || component_type === 'checkboxgroup' ? false : null
  }

  const getFormComponent = (component_type: string, item: any) => {
    component_type = component_type.toLowerCase()
    if (component_type === 'checkbox') {
      return (
        <div className='checkbox-group'>
          <input
            type='checkbox'
            checked={getFilterValue(component_type, item)}
            id={item.text}
            onClick={e => {
              onChange(filter.filter_array_key || '', item, e.currentTarget.checked)
            }}
            onChange={() => console.log('')}
          />

          {item.tile_color && (
            <div className={`color-card`} style={{ backgroundColor: item.tile_color }}>
              <span></span>
            </div>
          )}
          <label className={`col-form-label ${item.component_type?.toLocaleLowerCase() === 'calendar' ? 'calendar-label' : ''}`} htmlFor={item.text}>
            {item.icon && <Icon icon={item.icon} type='regular' />} {item.title || item.text}
          </label>
        </div>
      )
    }

    if (component_type === 'autocomplete') {
      return (
        <AutoComplete
          name={id}
          items={getAutocompleteOptions()}
          searchOptions={{ name: 'id', placeholderText: item.title }}
          limit={100}
          icon={item.icon}
          type={'tags'}
          value={getFilterValue(component_type, item)}
          onChange={(v: any) => onChange(filter.filter_array_key || '', item, v)}
          showItemsOnFocus
        />
      )
    }

    if (component_type === 'calendar') {
      return (
        <CalendarInput
          onChange={(e: any) => {
            onChange(filter.filter_array_key || '', item, e)
          }}
          id={id}
          value={getFilterValue(component_type, item)}
        />
      )
    }

    if (component_type === 'textbox') {
      return (
        // <Textbox
        // 	name={id}
        // 	value={getFilterValue(component_type, item)}
        // 	onChange={(e: any) => {
        // 		onChange(filter.filter_array_key || '', item, e)
        // 	}}
        // 	startIcon='search'
        // 	placeholderText='Search for your preferred experience'
        // />
        <div className='main-search'>
          <Textbox
            name={''}
            onChange={(e: any) => {
              const value = e.target.value
              setSearchText(value)
              if (value === '') {
                onChange(filter.filter_array_key || '', item, '')
              }
            }}
            buttonProps={{
              onClick: (e: any) => onChange(filter.filter_array_key || '', item, searchText),
              icon: 'search',
              text: '',
              state: 0,
              type: 1
            }}
            onKeyUp={(event: any) => {
              if (event.keyCode === 13) {
                onChange(filter.filter_array_key || '', item, searchText)
              }
            }}
            placeholderText='Search experiences'
          />
        </div>
      )
    }
  }

  let lookupValue = getKeyValue<any, any>(filter.lookup_array_key)(lookupItems)

  if (!!filter.lookup_array_key && !lookupValue.length) {
    return <> </>
  }

  return (
    <Fragment>
      <div className={`form-group nextto ${filter.component_type?.toLowerCase() === 'calendar' && 'calendar'}`}>
        {!!filter.expandable && (
          <AccordionItem
            title={filter.title}
            icon={filter.icon}
            items={getAccordionItems()}
            state={getAccordionState()}
            // onClick={() => console.log('::accordianclicked')}
          />
        )}

        {!filter.expandable && getFormComponent(filter.component_type || '', filter)}
      </div>
    </Fragment>
  )
}

import { Alert } from '@mit/hui'
import { getDateString } from 'Common'
import React from 'react'

interface Note {
  id?: string
  note?: string
  created_on?: string
  created_by?: string
  status?: { id: string; text: string }
  approver: {
    id: string
    text: string
  }
}

interface ApplicationNoteProps {
  notes: Note[]
}

export const ApplicationNote: React.FC<ApplicationNoteProps> = ({ notes }) => {
  const getStatusText = (status: string) => {
    switch (status) {
      case 'A':
        return 'Application Approved'
      case 'P':
        return 'Change Requested'
      case 'R':
        return 'Application Rejected'
    }
  }

  const getAlertText = (note: Note): any => {
    return (
      <>
        <h6>{getStatusText(note.status?.id ?? 'P')}</h6>
        <p>{note.note}</p>
        <span className=''>
          <b>
            {note.approver?.text} - {getDateString(note.created_on || '')}
          </b>
        </span>
      </>
    )
  }

  return (
    <>
      {notes.map(note => (
        <Alert type='info' text={<p>{getAlertText(note)}</p>} />
      ))}
    </>
  )
}

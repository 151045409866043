import React from 'react'
import { SkeletonLoader } from '@mit/hui'
import { ExperienceDetailInfoText } from './ExperienceDetailInfoText'
import { Opportunity } from 'Types'

interface Props {
  text: string
  opportunity: Opportunity
  isLoading?: boolean
  isHTML?: boolean
}

export const ExperienceDetailText: React.FC<Props> = ({ text, isHTML, isLoading, opportunity }) => {
  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <React.Fragment>
          {isHTML ? (
            <div className='experience-detail-handshake' dangerouslySetInnerHTML={{ __html: text }}></div>
          ) : (
            <div className='experience-detail'>{text}</div>
          )}
          <div className='experience-detail-sidebar-info sidebar-info-container'>
            <ExperienceDetailInfoText opportunity={opportunity} />
          </div>
        </React.Fragment>
      )}
    </>
  )
}

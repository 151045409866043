import { BaseController } from './BaseController'

import { Profile } from 'TemplateConstants/types'

export class ProfileController extends BaseController {
  async getProfile (): Promise<Profile> {
    const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getProfilePicture (): Promise<BinaryType> {
    const response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

    if (response.status === 200) {
      return response.blob()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}

import { Alert } from '@mit/hui'
import React from 'react'
import { Banner } from 'Types/banner'

interface Props {
  banner: Banner
}

export const BannerMessage: React.FC<Props> = ({ banner }) => {
  return (
    <>
      {!!banner.text && (
        <Alert
          type={banner.type}
          text={
            <>
              {' '}
              {/* <h3>{banner.type === 'error' ? 'Error' : 'Success'}</h3> */}
              {banner.text}{' '}
            </>
          }
        />
      )}
    </>
  )
}

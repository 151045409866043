import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { OpportunityController } from 'Api/controller'
import { Opportunity } from 'Types'
import { ButtonState, ButtonType, PageLayout } from '@mit/hui'
import { ExperienceDetailController } from './ExperienceDetailController'
import { useStoreActions, useStoreState } from 'Store'
import { ExperienceDetailText } from './ExperienceDetailText'
import { ApplicationModal } from '../application/ApplicationModal'
import { getDateString } from 'Common'
import { ExperienceDetailHeader } from './ExperienceDetailHeader'

interface TParams {
  id: string
}

export type SelectionsType = 'costs' | 'overview' | 'prerequisites' | 'safety_considerations'
const alternativePriorityText: { [key: string]: string } = {
  overview: 'overviewHTML'
}

export const ExperienceDetail: ({ match }: RouteComponentProps<TParams>) => JSX.Element = ({ match, history }: RouteComponentProps<TParams>) => {
  const [opportunity, setOpportunity] = useState<Opportunity>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [allTopics, setAllTopics] = useState<SelectionsType[]>([])
  const [currentSelection, setCurrentSelection] = useState<SelectionsType>('overview')
  const { showApplyModal } = useStoreState(store => store.globalModel)
  const { setHeroComponent, setWrapperClass } = useStoreActions(state => state.globalModel)
  const { lookupItems } = useStoreState(state => state.filterModel)

  useEffect(() => {
    getOpportunity()
    setWrapperClass('detail-wrapper')
    return () => {
      setHeroComponent(undefined)
      setWrapperClass(undefined)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (opportunity?.id) {
      getDetailTopics()
      let first_theme_color = opportunity.themes && opportunity.themes.length ? opportunity.themes[0].tile_color : null
      let tile_color: any = opportunity?.primary_theme?.tile_color || first_theme_color || 'blue'
      setHeroComponent({
        title: opportunity?.texts.title || '',
        subTitle: opportunity.texts.tagline || '',
        backgroundColor: tile_color,
        primaryButtonSubText: opportunity.application?.id ? `Applied on ${getDateString(opportunity.application?.date || '')}` : '',
        showBackButton: true,
        backButtonProps: {
          text: 'Back to experiences',
          type: ButtonType.Ghost,
          icon: 'chevron-left',
          onClick: () => {
            if (
              history.location.state &&
              // @ts-ignore
              !!history.location.state['prevPath']
            ) {
              sessionStorage.setItem(
                'currUrl',
                // @ts-ignore
                history.location.state['prevPath']
              )
              history.goBack()
            } else {
              sessionStorage.setItem('currUrl', '/')
              history.push('/')
            }
          },
          state: ButtonState.Enabled
        }
      })
    }
    //eslint-disable-next-line
  }, [opportunity])

  const getOpportunity = () => {
    setIsLoading(true)
    const controller = new OpportunityController()
    controller
      .getOpportunityDetails(match.params.id)
      .then(data => {
        setOpportunity(data)
        setIsLoading(false)
      })
      .catch(ex => {
        setIsLoading(false)
        console.log({ ex })
      })
  }

  const switchView = (currentView: SelectionsType) => {
    setCurrentSelection(currentView)
  }

  const getDetailTopics = () => {
    if (!opportunity) {
      return null
    }
    const topics: SelectionsType[] = ['overview', 'costs', 'safety_considerations', 'prerequisites']
    switchView('overview')
    setAllTopics(topics.filter(item => !!opportunity.texts[item]))
  }

  const getBlurb = (selection: SelectionsType): { text: string; isHTML?: boolean } => {
    const alternativeTextProperty = alternativePriorityText[selection]
    const texts: any = opportunity?.texts

    if (alternativeTextProperty && texts?.[alternativeTextProperty]) {
      return { text: texts?.[alternativeTextProperty] as string, isHTML: true }
    }

    if (opportunity?.texts[selection] && opportunity?.texts[selection]) {
      // @ts-ignore
      return { text: opportunity.texts[selection] }
    } else {
      return { text: 'No information currently available' }
    }
  }

  const getText = (selection: string) => {
    if (selection === 'safety_considerations') {
      return 'Safety Considerations'
    } else {
      return selection[0].toUpperCase() + selection.slice(1)
    }
  }

  const { text, isHTML } = getBlurb(currentSelection)

  return (
    <>
      {showApplyModal && opportunity && (
        <ApplicationModal
          opportunity_id={opportunity.id}
          application_id={isLoading ? '' : opportunity.application?.id || ''}
          formTemplate={opportunity.form_template.items || []}
          refreshOpportunity={getOpportunity}
          lookupItems={lookupItems}
        />
      )}
      <PageLayout
        navigation={<ExperienceDetailHeader opportunity={opportunity as Opportunity} title={getText(currentSelection)} isLoading={isLoading} />}
        startState={true}
        master={
          opportunity ? (
            <ExperienceDetailController opportunity={opportunity} selections={allTopics} onSelect={switchView} isLoading={isLoading} />
          ) : null
        }
        onBack={() => null}
        detail={<ExperienceDetailText text={text} isHTML={isHTML} opportunity={opportunity as Opportunity} isLoading={isLoading} />}
        masterTitle={''}
        detailTitle={''}
        compact
        fullWidth
      />
    </>
  )
}

import { Action, action } from 'easy-peasy'
import { ButtonProps } from '@mit/hui'
import { Auth } from 'Types'

export interface ApplicationValues {
  question: string
  answer: string | boolean
}

interface HeroComponentValue {
  title: string
  subTitle: string
  backgroundColor: string
  showPrimaryButton?: boolean
  primaryButtonSubText?: string
  primaryButtonProps?: ButtonProps
  showBackButton?: boolean
  backButtonProps?: ButtonProps
}

export interface HeroComponent {
  value: HeroComponentValue | undefined
}

export interface State {
  auth: Auth
  isLoading: boolean
  refreshOpportunities: boolean
  refreshLookups: boolean
  heroComponent: HeroComponent
  wrapperClass?: string
  showApplyModal: boolean
  initialLoad: boolean
}
export interface Actions {
  setAuth: Action<this, object>
  setIsLoading: Action<this, boolean>
  setRefreshOpportunities: Action<this>
  setRefreshLookups: Action<this>
  setHeroComponent: Action<this, HeroComponentValue | undefined>
  setWrapperClass: Action<this, string | undefined>
  setShowApplyModal: Action<this>
  setInitialLoad: Action<this, boolean>
}

export interface Thunks {}

export interface GlobalModel extends State, Actions, Thunks {}

export const globalModel: GlobalModel = {
  auth: {},
  isLoading: true,
  refreshOpportunities: false,
  refreshLookups: false,
  heroComponent: { value: undefined },
  showApplyModal: false,
  initialLoad: false,
  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setRefreshOpportunities: action(state => {
    state.refreshOpportunities = !state.refreshOpportunities
  }),

  setRefreshLookups: action(state => {
    state.refreshLookups = !state.refreshLookups
  }),
  setHeroComponent: action((state, payload: HeroComponentValue | undefined) => {
    state.heroComponent.value = payload
  }),
  setWrapperClass: action((state, payload) => {
    state.wrapperClass = payload
  }),
  setShowApplyModal: action(state => {
    state.showApplyModal = !state.showApplyModal
  }),
  setInitialLoad: action((state, payload) => {
    state.initialLoad = payload
  })
}

import { MobileView, SkeletonLoader, Button, ButtonType, Tooltip, Icon } from '@mit/hui'
import React, { useEffect, Fragment } from 'react'
import { useStoreState, useStoreActions } from 'Store'

import { FilterFormItem } from './FilterFormItem'
import { History } from 'history'
import { getDefualtFilters } from 'Api/mock/Filter'
import cloneDeep from 'clone-deep'

interface FilterFormProps {
  closeFilter?: any
  history: History
}

export const FilterForm: React.FC<FilterFormProps> = ({ closeFilter, history }) => {
  const { filtersCleared, opportunityQuery, opportunityQueryLoaded } = useStoreState(state => state.filterModel)
  const { setOpportunityQuery, setFilterCleared } = useStoreActions(state => state.filterModel)
  const { profile, profilePicture } = useStoreState(store => store.ProfileModel)
  const { opportunityFilters, lookupItems, preferredOpportunityQuery, mobileTempOpportunityQuery } = useStoreState(state => state.filterModel)
  const { initialLoad, auth } = useStoreState(state => state.globalModel)

  useEffect(() => {
    if (!initialLoad) {
      applyFilters()
    }
    //eslint-disable-next-line
  }, [preferredOpportunityQuery])

  const applyFilters = () => {
    let query = cloneDeep(preferredOpportunityQuery) || getDefualtFilters()
    setOpportunityQuery(query)
    setFilterCleared(false)
  }

  const applyStudentSuggestions = () => {
    const student = auth.student
    let query = getDefualtFilters()

    if (student?.classification) {
      query.degree_level = [{ id: student.classification }]
    }
    if (student?.majors) {
      query.major = student.majors.map(id => ({ id: id }))
    }
    if (student?.year) {
      query.class_year = [{ id: parseInt(student?.year + '') }]
    }
    setOpportunityQuery(query)
    setFilterCleared(false)
  }

  const clearFilters = () => {
    setOpportunityQuery(getDefualtFilters())
    setFilterCleared(true)
    if (history.location.search && history.location.search.length) {
      history.push({ search: undefined })
    }
  }

  const isNotEmpty = (object: any) => {
    let hasData = false
    Object.values(object).forEach(value => {
      if (!value) {
        return
      }
      if ((typeof value === 'string' && value.length) || (Array.isArray(value) && value.length) || (typeof value === 'boolean' && value)) {
        hasData = true
      }
    })
    return hasData
  }

  return (
    <Fragment>
      {!!!opportunityFilters.length || !!!Object.keys(lookupItems).length || !opportunityQueryLoaded ? (
        <SkeletonLoader type='list' />
      ) : (
        <div className='filter-container'>
          <div className='profile-filter'>
            {!!profile.display_name && !!profilePicture && (
              <div className='profile-details-container'>
                <img src={profilePicture} alt={profile.display_name} />
                <div>
                  <div className='profile-details'>
                    <span className='name'>{`${profile.display_name}'s`}</span>
                    <br />
                  </div>
                  <div className='filter-preferences'>
                    <span>Personalized Suggestions</span>
                    <br />
                    {auth.student !== null && (
                      <div className='d-flex align-items-center'>
                        <Button text='Apply Suggestions' type={ButtonType.Ghost} padded={false} onClick={applyStudentSuggestions} />
                        <Tooltip text='Apply defult filters based on your student classification.' placement='auto'>
                          <div className='ml-1' style={{ width: '1rem', marginTop: '0.1rem' }}>
                            <Icon icon='info-circle' type='light' />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                    {isNotEmpty(preferredOpportunityQuery) && (
                      <div className='d-flex align-items-center'>
                        <Button text='Apply My Preferences' type={ButtonType.Ghost} padded={false} onClick={applyFilters} />
                        <Tooltip text='Applies your previously saved preferred filters. ' placement='auto'>
                          <div className='ml-1' style={{ width: '1rem', marginTop: '0.1rem' }}>
                            <Icon icon='info-circle' type='light' />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className='filter-options'>
              <>
                {opportunityFilters?.map((filter, index: number) => {
                  return <FilterFormItem key={index} filter={filter} lookupItems={lookupItems} history={history} />
                })}
                {!filtersCleared && isNotEmpty(opportunityQuery) && (
                  <div className='clear-filters'>
                    <Button text='Clear Filters' type={ButtonType.Ghost} padded={false} onClick={clearFilters} icon='eraser' />
                  </div>
                )}
              </>
            </div>
          </div>
          <div className='filter-btn mt-3 mb-3 ml-4'>
            <Button text='Apply Filters' onClick={() => closeFilter(MobileView.Detail)} type={ButtonType.Primary} icon='filter' />
            <Button
              text='Cancel'
              onClick={() => {
                setOpportunityQuery(cloneDeep(mobileTempOpportunityQuery || getDefualtFilters()))
                closeFilter(MobileView.Detail)
              }}
              type={ButtonType.Primary | ButtonType.Ghost}
              icon=''
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}

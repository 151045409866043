import { LookupsController, OpportunityController, ProfileController } from 'Api/controller'
import { getPreferredFilters } from 'Common/Filter'
import React, { useEffect } from 'react'
import { useStoreActions } from 'Store'

export const AppInitializer: React.FC = () => {
  const { setHasAccess, setIsLoading } = useStoreActions(action => action.LoaderModel)
  const { setOpportunityFilters, setNotificationOptIn, setLookupItems, setPreferredOpportunityQuery } = useStoreActions(
    actions => actions.filterModel
  )
  const { setIsStudent, setHasApplications } = useStoreActions(actions => actions.profileModel)
  const { setAuth } = useStoreActions(action => action.globalModel)

  useEffect(() => {
    initialize().catch(error => console.error(error))

    // eslint-disable-next-line
  }, [])

  const initialize = async (): Promise<void> => {
    const controller = new OpportunityController()
    const profileController = new ProfileController()
    const lookupController = new LookupsController()

    let resp: any = await Promise.allSettled([
      controller.getOpportunityFilters(),
      controller.getFilterPreference(),
      lookupController.getFilterOptions(),
      profileController.getInitialization()
    ])

    //filters
    if (resp[0].status === 'fulfilled') {
      let d = resp[0].value.map((d: any) => {
        d.component_type = d.component_type || 'checkboxgroup'
        return d
      })
      setOpportunityFilters(d || {})
    }
    if (resp[1].status === 'fulfilled') {
      setPreferredOpportunityQuery(getPreferredFilters(resp[1].value.filters))
      setNotificationOptIn(resp[1].value.notification_opt_in)
    }

    if (resp[2].status === 'fulfilled') {
      setLookupItems(resp[2].value || {})
    }
    if (resp[3].status === 'fulfilled') {
      setIsStudent(resp[3].value.is_student || false)
      setHasApplications(resp[3].value.has_applications || false)
      setHasAccess(resp[3].value)
      setAuth(resp[3].value)
    }

    // setHasAccess(true)
    setIsLoading(false)
  }

  return <></>
}

import React from 'react'

import { NavBar, NavBarTypes, NavItemState, ResponsiveTypes } from '@mit/hui'
import { useStoreState } from 'Store'
import { useLocation } from 'react-router-dom'
import { getEnvForUrl } from 'Common'

interface Props {
  placeholder?: any
}

export const MainNavBar: React.FC<Props> = () => {
  const { has_applications } = useStoreState(store => store.profileModel)
  const { auth } = useStoreState(store => store.globalModel)

  let path: any = useLocation()
  path = path.pathname

  let tabs: any = [
    {
      index: 0,
      icon: '',
      iconOnly: false,
      text: 'Experiences',
      to: '/',
      onClick: () => sessionStorage.setItem('currUrl', '/'),
      state: path === '/' ? NavItemState.Active : NavItemState.None,
      overrideState: () => (path === '/' ? NavItemState.Active : NavItemState.None)
    }
  ]

  if (has_applications) {
    tabs.push({
      index: 1,
      icon: '',
      iconOnly: false,
      text: 'My Applications',
      to: '/applications',
      onClick: () => sessionStorage.setItem('currUrl', '/applications'),
      state: path === '/applications' ? NavItemState.Active : NavItemState.None,
      overrideState: () => (path === '/applications' ? NavItemState.Active : NavItemState.None)
    })
  }

  tabs.push({
    index: 2,
    icon: '',
    iconOnly: false,
    text: 'FAQ',
    to: '/',
    state: NavItemState.None,
    onClick: () => window.open('https://elo.mit.edu', '_blank'),
    overrideState: () => NavItemState.None
  })

  console.log('hasAdmin', auth)

  if (auth.has_admin_access) {
    tabs.push({
      index: 3,
      icon: '',
      iconOnly: false,
      text: 'ELx Admin',
      to: '/',
      state: NavItemState.None,
      onClick: () => {
        const env = getEnvForUrl()
        console.log(`https://elx-admin${env}.mit.edu`)
        window.open(`https://elx-admin${env}.mit.edu`, '_blank')
      },
      overrideState: () => NavItemState.None
    })
  }

  const overrideState = () => {
    // let pathFound = tabs.find((row:any) => row.to == path)

    // if(pathFound)

    return NavItemState.None
  }

  return (
    <NavBar
      type={NavBarTypes.IconText}
      tabs={tabs}
      responsiveType={ResponsiveTypes.CollapseAll}
      maxWidth={960}
      minWidth={961}
      overrideState={overrideState}
    />
  )
}

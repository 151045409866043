import { ILoaderModel, LoaderModel } from './Loader'
import { IProfileModel, ProfileModel } from './Profile'
import { IRegionModel, RegionModel } from './Region'

export interface ITemplateModel {
  LoaderModel: ILoaderModel
  ProfileModel: IProfileModel
  RegionModel: IRegionModel
}

export const TemplateModel: ITemplateModel = {
  LoaderModel,
  ProfileModel,
  RegionModel
}

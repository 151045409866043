import { BaseController } from './BaseController'

import { Lookups } from 'Types'

export class LookupsController extends BaseController {
  async getFilterOptions (): Promise<Lookups> {
    let response = await this.useFetch('GET', 'get/lookups', `${this.apiHost}/${this.apiPath}/lookups`)

    if (response.status === 200) {
      let data = await response.json()
      data.keywords = data.keywords.filter((item: any) => item.active)
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }
}

import { BaseController } from './BaseController'

import { ApplicationWithDocuments, NewApplication, Opportunity, OpportunityFilter } from 'Types'
import { OpportunityQuery } from 'Types'
export class OpportunityController extends BaseController {
  async getOpportunities (only_my_applications?: boolean): Promise<Opportunity[]> {
    let response = await this.useFetch(
      'GET',
      'get/opportunities',
      `${this.apiHost}/${this.apiPath}/opportunity${only_my_applications ? '?only_my_applications=true' : ''}`
    )

    if (response.status === 200) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getOpportunitiesFiltered (opportunityQuery: OpportunityQuery): Promise<Opportunity[]> {
    let queryString = '',
      filters: any = { ...opportunityQuery }

    queryString = Object.keys(filters)
      .map((key: string) => {
        let value: any = filters[key]
        let query
        if (Array.isArray(value)) {
          query = value.length ? `${key}=${value.map(row => encodeURIComponent(row.id)).join(',')}` : null
        } else {
          query = value ? `${key}=${encodeURIComponent(value.trim())}` : null
        }
        return query
      })
      .filter((row: any) => row)
      .join('&')

    let response = await this.useFetch('GET', 'get/opportunities', `${this.apiHost}/${this.apiPath}/opportunity?${queryString}`)

    if (response.status === 200) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getOpportunityFilters (): Promise<OpportunityFilter[]> {
    let response = await this.useFetch('GET', 'get/opportunities/filters', `${this.apiHost}/${this.apiPath}/opportunity/filters`)

    if (response.status === 200) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getOpportunityDetails (id: string): Promise<Opportunity> {
    let response = await this.useFetch('GET', `get/opportunity/${id}`, `${this.apiHost}/${this.apiPath}/opportunity/${id}`)

    if (response.status === 200) {
      return response.json()
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async createOpportunityApplication (opportunity_id: string, body: NewApplication): Promise<NewApplication> {
    let response = await this.useFetch(
      'PUT',
      `put/opportunity/${opportunity_id}`,
      `${this.apiHost}/${this.apiPath}/opportunity/${opportunity_id}/application`,
      body
    )

    if (response.status === 200) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async updateOpportunityApplication (opportunity_id: string, application_id: string, body: NewApplication): Promise<NewApplication> {
    let response = await this.useFetch(
      'PUT',
      `put/opportunity/${opportunity_id}`,
      `${this.apiHost}/${this.apiPath}/opportunity/${opportunity_id}/application/${application_id}`,
      body
    )

    if (response.status === 200) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async uploadApplicationDocument (url: string, file: File): Promise<any> {
    let response = await this.useFetch('PUT', `put/opportunityApplicationUpload/${url}`, url, file, {}, true, file.name)

    if (response.status === 200) {
      return true
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getFilterPreference (): Promise<any> {
    let response = await this.useFetch('GET', 'get/opportunity/preferred_filters', `${this.apiHost}/${this.apiPath}/opportunity/preferred_filters`)

    if (response.status === 200) {
      return response.json()
    } else {
      return Promise.reject(await response.text)
    }
  }

  async setFilterPreference (filterObject: OpportunityQuery, notificationOptIn: boolean = false): Promise<any> {
    let response = await this.useFetch('PUT', 'put/opportunity/preferred_filters', `${this.apiHost}/${this.apiPath}/opportunity/preferred_filters`, {
      filters: filterObject,
      notification_opt_in: notificationOptIn
    })

    if (response.status === 200) {
      return response.json
    } else {
      return Promise.reject(await response.text)
    }
  }

  async getOpportunityApplication (opportunity_id: string, application_id: string): Promise<ApplicationWithDocuments> {
    let response = await this.useFetch(
      'GET',
      `get/opportunity/${opportunity_id}/application/${application_id}`,
      `${this.apiHost}/${this.apiPath}/opportunity/${opportunity_id}/application/${application_id}`
    )

    if (response.status === 200) {
      let resp: ApplicationWithDocuments = await response.json()
      resp.documents = resp.documents?.map(item => ({ ...item, name: item.filename, type: item.content_type }))
      return resp
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }
}

import React from 'react'
import { Alert } from '@mit/hui'
import { getDateString } from 'Common'

interface UropAlertProps {
  theme_id: number
  term: string
  urop_funded_deadline: string
  sponsor_funded_deadline: string
}

export const UropAlert: React.FC<UropAlertProps> = ({ theme_id, term, urop_funded_deadline, sponsor_funded_deadline }) => {
  if (theme_id !== 1) {
    return null
  }

  return (
    <Alert
      text={
        <>
          <h5>IMPORTANT NOTE ABOUT UROPS</h5>
          <span>
            Students hired for UROP positions posted on ELx (even if “approved” in ELx) must also submit a separate formal UROP application through
            the{' '}
            <a rel='noopener noreferrer' href='https://eduapps.mit.edu/uropweb/home.mit' target='_blank'>
              UROP application system
            </a>{' '}
            <a
              rel='noopener noreferrer'
              href='https://urop.mit.edu/research-exploration/urop/apply/application-checklist/mit-and-exchange-student-checklist'
              target='_blank'
            >
              (see website for details)
            </a>{' '}
            in order to be paid, receive credit, and have the UROP become part of their MIT record. Formal UROP application deadlines for {term} are:
            <ul className='my-2'>
              <li className='font-weight-bold'>UROP Funded Deadline: {getDateString(urop_funded_deadline || '')}</li>
              <li className='font-weight-bold'>Sponsor Funded Deadline: {getDateString(sponsor_funded_deadline || '')}</li>
            </ul>
            Please <a href='mailto:urop@mit.edu'>contact the UROP Office</a> with any questions.
          </span>
        </>
      }
      type={'info'}
    />
  )
}

import cloneDeep from 'clone-deep'
import { Action, action } from 'easy-peasy'
import { getDefualtFilters } from 'Api/mock/Filter'
import { Lookups, OpportunityFilter, OpportunityQuery } from 'Types'

export interface State {
  opportunityQuery: OpportunityQuery
  notification_opt_in: boolean
  preferredOpportunityQuery: OpportunityQuery | null
  mobileTempOpportunityQuery: OpportunityQuery | null
  opportunityQueryLoaded: boolean
  opportunityFilters: OpportunityFilter[]
  lookupItems: Lookups
  refreshOpportunityFilters: boolean
  filtersCleared: boolean
}
export interface Actions {
  setOpportunityQuery: Action<this, OpportunityQuery>
  setNotificationOptIn: Action<this, boolean>
  setPreferredOpportunityQuery: Action<this, OpportunityQuery>
  setMobileTempOpportunityQuery: Action<this>
  setOpportunityFilters: Action<this, OpportunityFilter[]>
  setLookupItems: Action<this, Lookups>
  setRefreshOpportunityFilters: Action<this>
  setFilterCleared: Action<this, boolean>
}
export interface Thunks {
  // updateCounterThunk: Thunk<this, number>
}

export interface FilterModel extends State, Actions, Thunks {}

export const filterModel: FilterModel = {
  opportunityQuery: getDefualtFilters(),
  notification_opt_in: false,
  preferredOpportunityQuery: null,
  mobileTempOpportunityQuery: null,
  opportunityQueryLoaded: false,
  opportunityFilters: [],
  lookupItems: {},
  refreshOpportunityFilters: false,
  filtersCleared: true,

  // ACTIONS
  setOpportunityQuery: action((state, payload) => {
    sessionStorage.setItem('oppQuery', JSON.stringify(payload))
    state.opportunityQuery = payload
    state.opportunityQueryLoaded = true
  }),
  setNotificationOptIn: action((state, payload) => {
    state.notification_opt_in = payload
  }),
  setPreferredOpportunityQuery: action((state, payload) => {
    state.preferredOpportunityQuery = payload
  }),
  setMobileTempOpportunityQuery: action(state => {
    state.mobileTempOpportunityQuery = cloneDeep(state.opportunityQuery)
  }),

  setOpportunityFilters: action((state, payload) => {
    state.opportunityFilters = payload
  }),
  setLookupItems: action((state, payload) => {
    state.lookupItems = payload
  }),
  setRefreshOpportunityFilters: action(state => {
    state.refreshOpportunityFilters = !state.refreshOpportunityFilters
  }),
  setFilterCleared: action((state, payload) => {
    state.filtersCleared = payload
  })

  // // THUNKS
  // updateCounterThunk: thunk(async (actions, payload) => {
  // 	setTimeout(() => actions.updateCounter(payload), 1000)
  // })
}

import React, { useState } from 'react'
import { SkeletonLoader, Button, Icon } from '@mit/hui'
import { SelectionsType } from './ExperienceDetail'
import { getDateString } from 'Common'
import { Opportunity } from 'Types'
import { useStoreActions } from 'Store'
import { ExperienceDetailInfoText } from './ExperienceDetailInfoText'
// import { Map } from '../Map'

interface ControllerProps {
  opportunity: Opportunity
  selections: SelectionsType[]
  onSelect: (selection: SelectionsType) => void
  isLoading: boolean
}

export const ExperienceDetailController: React.FC<ControllerProps> = ({ selections, onSelect, isLoading, opportunity }) => {
  const [activeSelection, setActiveSelection] = useState(0)
  // const { is_student } = useStoreState(state => state.profileModel)
  const { setShowApplyModal } = useStoreActions(state => state.globalModel)

  const application = opportunity?.application

  const getText = (selection: string) => {
    if (selection === 'safety_considerations') {
      return 'Safety Considerations'
    } else {
      return selection[0].toUpperCase() + selection.slice(1)
    }
  }

  const getIcon = (selection: SelectionsType) => {
    switch (selection) {
      case 'costs':
        return 'wallet'
      case 'overview':
        return 'file-alt'
      case 'prerequisites':
        return 'clipboard-list'
      case 'safety_considerations':
        return 'hard-hat'
    }
  }

  const handleApplyButtonPress = () => {
    if (opportunity?.application_config?.type === 'form') {
      setShowApplyModal()
    } else if (opportunity?.application_config?.type === 'external_application') {
      window.open(`https://${opportunity.texts.external_application_url}`, '_blank')
    } else if (opportunity?.application_config?.type === 'email') {
      const contactEmail =
        opportunity.contact_email != null && opportunity.contact_email !== '' ? opportunity.contact_email : opportunity.contact?.krb_name + '@mit.edu'
      window.open(`mailto:${contactEmail}?subject=${opportunity.texts.title}`)
    }
  }

  return (
    <>
      {isLoading ? (
        <SkeletonLoader type='list' />
      ) : (
        <div className='filter-container'>
          {/* {is_student && ( */}
          <div className='apply-cta'>
            <Button text={!!application?.id ? 'My Application' : 'Apply'} onClick={handleApplyButtonPress} />
            <div className='apply-cta-subtext'>{application?.id ? `Applied on ${getDateString(opportunity.application?.date || '')}` : ''}</div>
          </div>
          {/* )} */}
          <div className='filter-options'>
            {selections.map((selection, index) => (
              <div className={`filter-options-btn ${activeSelection === index && 'active'}`} key={index}>
                <Button
                  type={32}
                  text={getText(selection)}
                  onClick={() => {
                    setActiveSelection(index)
                    onSelect(selection)
                  }}
                  icon={getIcon(selection)}
                />
                {activeSelection === index && <Icon type='solid' icon='chevron-right' />}
              </div>
            ))}
          </div>
          <div className='sidebar-info-container'>
            <ExperienceDetailInfoText opportunity={opportunity} />
          </div>
        </div>
      )}
    </>
  )
}

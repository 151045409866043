import { Button, ButtonType, Icon, TemplateModal, TemplateModalAlignment, TemplateModalSize, Tooltip } from '@mit/hui'
import React, { useState } from 'react'
import { useStoreActions, useStoreState } from 'Store'
import { OpportunityController } from 'Api/controller'

interface Props {
  onClick?: () => void
}

export const SaveFilter: React.FC<Props> = ({ onClick }) => {
  const [showPreferenceModal, setShowPreferenceModal] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { opportunityQuery, notification_opt_in } = useStoreState(state => state.filterModel)
  const { setPreferredOpportunityQuery } = useStoreActions(action => action.filterModel)
  const [optIn, setOptIn] = useState<boolean>(notification_opt_in)

  const handleClick = () => {
    const controller = new OpportunityController()
    setIsSubmitting(true)
    controller
      .setFilterPreference(opportunityQuery, optIn)
      .then(() => {
        setIsSubmitting(false)
        setPreferredOpportunityQuery(opportunityQuery)
        if (onClick) {
          onClick()
        }
        setShowPreferenceModal(false)
      })
      .catch(ex => console.warn(ex))
  }

  return (
    <div className='d-flex align-items-center'>
      <Button text='Save as Preference' icon='save' type={ButtonType.Ghost} padded={false} onClick={() => setShowPreferenceModal(true)} />
      <Tooltip
        text='Saves the current selected filters as your preferred filters. Your preferred filters will default when you first enter the application.'
        placement='auto'
      >
        <div className='ml-1 info-circle' style={{ width: '1rem' }}>
          <Icon icon='info-circle' type='light' />
        </div>
      </Tooltip>
      <TemplateModal
        name='Application'
        show={showPreferenceModal}
        bodyAlignment={TemplateModalAlignment.Center}
        onClose={() => setShowPreferenceModal(false)}
        noExternalClose={false}
        size={TemplateModalSize.Large}
        body={
          <>
            <br />
            <p style={{ fontSize: '1rem' }}>
              Save the current selected filters as your preferred filters. Your preferred filters will default when you first enter the application.
            </p>
            {/* <Checkbox
							label='Send me updates when new opportunities arise matching my filters'
							checked={optIn}
							onClick={() => setOptIn(!optIn)}
							register={() => null}
						/> */}
            <div className='checkbox-group'>
              <input id={'opt_in'} type='checkbox' checked={optIn} onClick={() => setOptIn(!optIn)} />
              <label className={`col-form-label ml-2 font-weight-400`} htmlFor={'opt_in'} style={{ userSelect: 'none', fontWeight: 500 }}>
                Send me weekly updates when new opportunities match my preferred filters.{' '}
              </label>
            </div>
          </>
        }
        footer={<Button text='Save Preference' onClick={handleClick} type={ButtonType.Primary} isBusy={isSubmitting} />}
        header={<h4>Save as preference</h4>}
      />
    </div>
  )
}

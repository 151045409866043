import React, { useEffect, useRef, useState } from 'react'
import { Button, FileUpload, Icon, SkeletonLoader, TemplateModal, TemplateModalSize } from '@mit/hui'
import { useStoreActions, useStoreState } from 'Store'
import { useForm } from 'react-hook-form'
import { FormField } from '../form'

import { OpportunityController } from 'Api/controller'
import { Banner, NewApplication, ApplicationWithDocuments, FormTemplate, Lookups } from 'Types'
import { BannerMessage } from '../form/BannerMessage'
import { ApplicationNote } from './ApplicationNote'
// import { UropAlert } from 'Components/alert/UropAlert'
// import { AppliedApplicationForm } from '../form/opportunity/AppliedApplicationForm'

interface Props {
  application_id: string
  opportunity_id: string
  lookupItems: Lookups
  show?: boolean
  formTemplate?: FormTemplate[]
  refreshOpportunity: () => void
}

const controller = new OpportunityController()

export const ApplicationModal: React.FC<Props> = ({ application_id, opportunity_id, lookupItems, formTemplate, refreshOpportunity }) => {
  const [files, setFiles] = useState<File[]>([])
  const [banner, setBanner] = useState<Banner>({ type: 'error', text: '' })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingApplication, setIsLoadingApplication] = useState<boolean>(false)
  const [appliedApplication, setAppliedApplication] = useState<ApplicationWithDocuments>({})
  const topRef = useRef<HTMLDivElement>(null)
  // const fileUploadRef = useRef<HTMLDivElement>(null)

  const { showApplyModal } = useStoreState(store => store.globalModel)
  const { setShowApplyModal } = useStoreActions(state => state.globalModel)
  const { setHasApplications } = useStoreActions(state => state.profileModel)

  const methods = useForm<any>({
    defaultValues: {
      form_data: formTemplate
    },
    reValidateMode: 'onChange'
  })
  const { handleSubmit, watch, trigger, errors, reset } = methods
  let formData = watch()

  useEffect(() => {
    if (!!application_id) {
      getApplication()
    }
    //eslint-disable-next-line
  }, [application_id])

  const getApplication = (newAppllicationId?: string) => {
    setIsLoadingApplication(true)
    controller
      .getOpportunityApplication(opportunity_id, newAppllicationId || application_id)
      .then(data => {
        setIsLoadingApplication(false)
        setAppliedApplication(data)
        reset({ form_data: data.form_json })
      })
      .catch(error => {
        setIsLoadingApplication(false)
      })
  }

  const onSubmit = (data: any) => {
    console.log(data)

    let body: NewApplication = {
      documents: !!files.length
        ? files.map(
            (file: File) =>
              ({
                ...file,
                name: file.name,
                content_type: file.type,
                url: null
              } as any)
          )
        : [],
      form_json: formTemplate?.map((row, index) => ({
        ...row,
        value: formData?.form_data[index]?.value
      }))
    }
    setIsLoading(true)

    if (application_id) {
      controller
        .updateOpportunityApplication(opportunity_id, application_id, body)
        .then(data => {
          uploadFiles(data, true)
        })
        .catch(error => {
          setIsLoading(false)
          scrollToTop()
          setBanner({ type: 'error', text: error })
        })
    } else {
      controller
        .createOpportunityApplication(opportunity_id, body)
        .then(data => {
          uploadFiles(data)
        })
        .catch(error => {
          setIsLoading(false)
          scrollToTop()
          setBanner({ type: 'error', text: error })
        })
    }
  }

  const uploadFiles = async (data: NewApplication, update?: boolean) => {
    if (data.documents?.length) {
      let promises: any = data.documents
        ?.map((file: any, index: number) => ({ ...file, index }))
        .filter(file => file.url)
        .map((file: any) => controller.uploadApplicationDocument(file.url, files[file.index]))

      await Promise.allSettled(promises)

      //check for any upload errors
      //move this inside the component
      //eslint-disable-next-line
      // let newFiles = files.map((file, index) => ({
      // 	...file,
      // 	uploadStatus: resp[index].status
      // }))
    }
    refreshOpportunity()
    setIsLoading(false)
    scrollToTop()
    setBanner({ type: 'success', text: update ? 'Application Updated' : 'Application Submitted' })
    setHasApplications(true)
    // getApplication(data.id)
  }

  const onPressSubmitButton = async (auto_approve?: boolean) => {
    const isValid = await trigger()
    setBanner({ type: 'info', text: '' })

    if (isValid) onSubmit(formData)
    else {
      console.log('errrors', errors)
      setBanner({
        type: 'error',
        text: 'Please fill in all the required fields'
      })
      scrollToTop()
    }
  }

  const scrollToTop = () => {
    if (topRef && topRef.current) topRef.current.scrollIntoView()
  }

  console.log('show applied', !!application_id || !!appliedApplication.id)

  return (
    <TemplateModal
      show={showApplyModal}
      onClose={setShowApplyModal}
      noExternalClose={true}
      body={
        isLoadingApplication ? (
          <SkeletonLoader />
        ) : (
          <>
            <div ref={topRef} />
            <BannerMessage banner={banner} />
            <ApplicationNote notes={appliedApplication.notes ?? []} />
            {/* <UropAlert
              theme_id={((appliedApplication?.primary_theme?.id as unknown) as number) ?? 0}
              sponsor_funded_deadline={lookupItems?.urop_deadlines?.sponsor_funded_deadline ?? ''}
              urop_funded_deadline={lookupItems?.urop_deadlines?.urop_funded_deadline ?? ''}
              term={lookupItems?.urop_deadlines?.term ?? ''}
            /> */}
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='d-flex application-header'>
                  <div className='application-header-icon'>
                    <Icon icon='clipboard-list-check' type='light' />
                  </div>
                  <div className='application-header-instructions'>
                    Before you submit your application, please fill in the following information to allow the approval process to go more fluidly.
                  </div>
                </div>

                {formTemplate ? (
                  formTemplate.map((row, index) => (
                    <div className='application-form-field'>
                      <FormField
                        //    ref={fileUploadRef}
                        key={`row-item-${index}`}
                        name={`form_data[${index}].value`}
                        editor={row.component_type === 'textarea' ? 'textarea' : 'textbox'}
                        label={row.text || ''}
                        // onBlur={(e: React.FormEvent<HTMLInputElement>) => {
                        // 	saveAnswer(row, e.currentTarget.value)
                        // }}
                        required={row.required}
                        methods={methods}
                        properties={{ rows: 5, placeholderText: '' }}
                        value={row.value}
                      />
                    </div>
                  ))
                ) : (
                  <></>
                )}
                <div className='d-flex application-upload'>
                  <FileUpload
                    multiple
                    acceptedMimeTypes={['image/jpeg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf']}
                    onConfirm={(file: File) => console.log('CONFIRM')}
                    onChange={(files: File[]) => setFiles(files)}
                    displayButtons={false}
                    clearFilesAfterConfirm={false}
                    initialValue={appliedApplication.documents as File[]}
                  />
                  <div className='application-upload-instructions'>
                    <p>Upload your resume with a cover letter, or an additional essay as needed</p>

                    <p>This will be sent both to 3rd parties involved in the decision making as well as head of departments.</p>
                  </div>
                </div>

                <div className='mt-3 d-flex justify-content-end'>
                  <Button
                    text={!!application_id ? 'Update' : 'Submit'}
                    onClick={() => {
                      onPressSubmitButton()
                      // setShowApplyModal()
                      // document.getElementById('root')?.classList.remove('modal-open')
                      // console.log(values)
                    }}
                    icon='paper-plane'
                    isBusy={isLoading}
                  />
                </div>
              </form>
            </>
            {/* )} */}
          </>
        )
      }
      bodyAlignment={1}
      footer={
        <>
          {/* <Button text='Save & Close' type={ButtonType.Primary | ButtonType.Ghost} icon='save' /> */}
          {/* <Button
						text='Submit'
						onClick={() => {
							setShowApplyModal()
							// document.getElementById('root')?.classList.remove('modal-open')
							// console.log(values)
						}}
						icon='paper-plane'
					/> */}
        </>
      }
      name={'Application'}
      header={<h4>Application</h4>}
      size={TemplateModalSize.Large}
    />
  )
}

import { Opportunity } from 'Types'
import { History } from 'history'

export const getStatusText = (status: string | number | undefined, has_pending_changes?: boolean): string => {
  // if (has_pending_changes) return 'Pending Changes'

  switch (status) {
    case 'A':
      return 'Approved'
    case 'R':
      return 'Rejected'
    case 'P':
      return 'Not Approved'
    case 'E':
      return 'Expired'
  }
  return 'Live'
}

export const getStatusClass = (status: string | number | undefined): string => {
  if (status === 'R' || status === 'P') {
    return 'status-not-approved'
  } else {
    return 'status-approved'
  }
}

export const getStatusType = (status: string | number | undefined, has_pending_changes?: boolean): any => {
  // if (has_pending_changes) return 'warning'

  if (status === 'A' || status === 'L') return 'success'

  return 'primary'
}

export const getCardItems = (opportunities: Opportunity[], history: History): any => {
  return opportunities.map((row: Opportunity) => {
    let tile_color: any = row.primary_theme?.tile_color
    console.log(row.texts.title)
    let data: any = {
      title: row.texts?.title || '',
      subTitles: [
        {
          icon: 'map-marker-alt',
          text: !row.is_remote ? row.location?.city || row.location?.country?.text || row.location?.text || 'Remote' : 'Remote'
        },
        { icon: 'calendar', text: row.terms?.length > 0 ? row.terms.map(term => term.text).join(', ') : null },
        { icon: row.primary_theme?.icon, text: ' ', additionalIcons: row.themes?.map(theme => theme.icon) }
      ].filter(row => row.text),
      linkTo: `/experience/${row.id}`,
      overview: row.texts.overview,
      backgroundColor: tile_color,
      status: !!row.application?.id
        ? {
            type: 'success',
            text: 'Applied'
          }
        : null
      //onClick: () => handleClickCardClick(row, history)
    }
    return data
  })
}
/*  replaced with link on card
const handleClickCardClick = (row: Opportunity, history: History) => {
  sessionStorage.setItem('currUrl', `/experience/${row.id}`)
  history.push(`/experience/${row.id}`, {
    prevPath: history.location.pathname
  })
}*/

export const getUrl = (url: string) => {
  if (url) {
    if (url.indexOf('https://') === -1 && url.indexOf('http://') === -1) {
      return `https://${url}`
    }
  }
  return url
}

export const getThemeColor = (groupColorClass: string) => {
  switch (groupColorClass) {
    case 'red':
    default:
      return 'category-one'
    case 'pink':
      return 'category-two'
    case 'teal':
      return 'category-three'
    case 'blue':
      return 'category-four'
    case 'green':
      return 'category-five'
    case 'purple':
      return 'category-six'
    case 'pine':
      return 'category-seven'
  }
}

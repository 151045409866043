import { CounterModel, counterModel } from './counter'
import { GlobalModel, globalModel } from './global'
import { FilterModel, filterModel } from './filter'
import { ProfileModel, profileModel } from './profile'
import { ITemplateModel, TemplateModel } from 'TemplateConstants/store/model'

export interface Model extends ITemplateModel {
  counterModel: CounterModel
  globalModel: GlobalModel
  filterModel: FilterModel
  profileModel: ProfileModel
}

export const model: Model = {
  ...TemplateModel,
  counterModel,
  globalModel,
  filterModel,
  profileModel
}

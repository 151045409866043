import React, { useEffect } from 'react'
import { Profile, DropdownItemProps, DropDownState } from '@mit/hui'

import { AuthProvider } from 'TemplateConstants/authorization'
import { ProfileController } from 'TemplateConstants/controller'
import { useStoreActions, useStoreState } from 'Store'

const controller = new ProfileController()

export const LoggedInUser: React.FC = () => {
  const { profile, profilePicture } = useStoreState(store => store.ProfileModel)
  const { updateProfile, updateProfilePicture } = useStoreActions(actions => actions.ProfileModel)

  const subMenuData: DropdownItemProps[] = [
    {
      icon: 'sign-out',
      text: 'Logout',
      state: DropDownState.None,
      onClick: async () => {
        await new AuthProvider().signOut()
      }
    }
  ]

  useEffect(() => {
    if (profile.display_name === '') {
      controller
        .getProfile()
        .then(data => {
          updateProfile(data)
        })
        .catch(error => console.error(error))
    }
    if (profilePicture === '') {
      controller
        .getProfilePicture()
        .then(data => {
          const url = URL.createObjectURL(data)
          updateProfilePicture(url)
        })
        .catch(error => console.error(error))
    }
    // eslint-disable-next-line
  }, [])

  return <Profile name={profile?.display_name} subtitle={profile?.department} imageUrl={profilePicture} submenu={subMenuData} />
}

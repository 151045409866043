import React, { useEffect, useState } from 'react'
import { PageLayout, MobileView } from '@mit/hui'
import { useLocation } from 'react-router-dom'
import { FilterForm } from '../filter/FilterForm'
import { OpportunitiesList } from '../opportunity/OpportunitiesList'
import { History } from 'history'
import { Lookup, Opportunity } from 'Types'
import { OpportunityController } from 'Api/controller'
import { useStoreState, useStoreActions } from 'Store'
import { OpportunityListHeader } from '../opportunity/OpportunityListHeader'

interface Props {
  history: History
}

export const Experiences: React.FC<Props> = ({ history }: Props) => {
  const [view, setView] = useState(MobileView.Detail)
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { opportunityQuery, preferredOpportunityQuery, lookupItems } = useStoreState(state => state.filterModel)
  const { initialLoad } = useStoreState(state => state.globalModel)
  const { setInitialLoad } = useStoreActions(state => state.globalModel)
  const { setOpportunityQuery } = useStoreActions(state => state.filterModel)
  const location = useLocation()

  useEffect(() => {
    setIsLoading(true)
    if (initialLoad) {
      if (!!sessionStorage.getItem('oppQuery') && sessionStorage.getItem('oppQuery')!.length) {
        console.log('initial load query')
        setTimeout(() => {
          setOpportunityQuery(JSON.parse(sessionStorage.getItem('oppQuery')!))
        }, 50)
      }
      return
    }
    if (!!sessionStorage.getItem('currUrl') && location.hash.length) {
      const currUrl = sessionStorage.getItem('currUrl')
      sessionStorage.removeItem('currUrl')
      if (!(history.location.pathname === currUrl)) {
        history.push(currUrl || '', {
          prevPath: history.location.pathname
        })
        return
      } else {
        setInitialLoad(true)
        return
      }
    }
    let oppQuery: any
    if (!!sessionStorage.getItem('oppQuery') && sessionStorage.getItem('oppQuery')!.length) {
      oppQuery = JSON.parse(sessionStorage.getItem('oppQuery')!)
    } else if (preferredOpportunityQuery && Object.values(preferredOpportunityQuery).length) {
      oppQuery = preferredOpportunityQuery
    }
    console.log(history.location.search)
    if (!oppQuery || checkSearch()) {
      setInitialLoad(true)
    } else {
      updateQuery(oppQuery)
      setTimeout(() => setInitialLoad(true), 150)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (initialLoad) {
      updateQuery(opportunityQuery)
    }
    //eslint-disable-next-line
  }, [opportunityQuery])

  const checkSearch = () => {
    if (!lookupItems?.themes) {
      return
    }
    if (history.location.search.length) {
      const searchItems = history.location.search
        .slice(8)
        .replaceAll('%20', ' ')
        .split('&')
      const { themes } = lookupItems
      const filtersFromUrl: Lookup[] = []
      searchItems.forEach(search => {
        filtersFromUrl.push({
          id: themes.find(theme => theme?.text === search)?.id || '',
          text: search
        })
      })
      setOpportunityQuery({
        ...opportunityQuery,
        themes: filtersFromUrl
      })
      return true
    }
  }

  const updateQuery = (query: any) => {
    setIsLoading(true)
    const controller = new OpportunityController()
    controller
      .getOpportunitiesFiltered(query)
      .then(data => {
        setOpportunities(data)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }

  return (
    <div className='experiences'>
      <PageLayout
        startState={true}
        onBack={() => {}}
        navigation={<OpportunityListHeader opportunities={opportunities} />}
        mobileView={view}
        master={<FilterForm closeFilter={setView} history={history} />}
        masterTitle='Clearances'
        detail={<OpportunitiesList history={history} openFilter={setView} opportunities={opportunities} isLoading={isLoading} />}
        detailTitle=''
        compact
        fullWidth
      />
    </div>
  )
}

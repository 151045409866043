import { BaseController } from './BaseController'

import { Profile } from 'Types'

export class ProfileController extends BaseController {
  async getProfile (key: string = ''): Promise<Profile> {
    let response = await this.useFetch('GET', `profile/${key}`, `${this.apiHost}/${this.apiPathDigitalId}/profile`)

    if (response.status === 200) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getProfilePicture (): Promise<any> {
    let response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

    if (response.status === 200) {
      return response.blob()
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getInitialization (): Promise<any> {
    let response = await this.useFetch('GET', 'initialization', `${this.apiHost}/${this.apiPath}/initialization `)

    if (response.status === 200) {
      return response.json()
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }
}

import { Icon, Textbox, TextboxType } from '@mit/hui'
import React, { useState } from 'react'

const minLengthShowSearch = 10

interface CheckboxGroupProps {
  items: any[]
  filter: any
  getFilterValue: (component_type: string, item: any) => any
  onChange: (filter_array_key: string, item: any, value?: boolean) => void
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ items, filter, getFilterValue, onChange }) => {
  const [search, setSearch] = useState<string | null>(null)

  const filteredItems =
    search !== '' && search !== null && items.length >= minLengthShowSearch
      ? items.filter(
          item =>
            (item.text && item.text.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
            (item.title && item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0)
        )
      : items

  return (
    <>
      <div className='checkbox-group-container'>
        {items.length >= minLengthShowSearch && (
          <div className='dropdown-search'>
            <Textbox
              name=''
              value=''
              onChange={(event: any) => setSearch(event.target.value)}
              startIcon='search'
              properties={{ 'aria-label': 'filter list' }}
              clearable={search !== null && search !== ''}
              onClear={() => setSearch('')}
              placeholderText={`Search for ${filter.title.toLowerCase()}`}
              type={TextboxType.IconStart}
            />
          </div>
        )}
        <div className={items.length >= minLengthShowSearch ? 'checkbox-group-container-items' : ''}>
          {filteredItems.length === 0 && <div className='checkbox-group-container-no-filters'>No filter options</div>}
          {filteredItems.map((item: any) => (
            <div className='checkbox-group'>
              <input
                type='checkbox'
                checked={getFilterValue('checkboxgroup', item)}
                id={item.text}
                onClick={e => {
                  onChange(filter.filter_array_key || '', item, e.currentTarget.checked)
                }}
                onChange={() => console.log('')}
              />

              {item.tile_color && (
                <div className={`color-card`} style={{ backgroundColor: item.tile_color }}>
                  <span></span>
                </div>
              )}
              <label
                className={`col-form-label ${item.component_type?.toLocaleLowerCase() === 'calendar' ? 'calendar-label' : ''}`}
                htmlFor={item.text}
              >
                {item.icon && <Icon icon={item.icon} type='regular' />} {item.title || item.text}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

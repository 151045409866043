import { getDefualtFilters } from 'Api/mock/Filter'

export const getPreferredFilters = (filters: any) => {
  let newFilters = getDefualtFilters()

  for (let i in filters) {
    newFilters[i] = filters[i]
  }

  return newFilters
}

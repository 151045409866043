import { BaseController as BaseApiController } from 'TemplateConstants/controller'

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  readonly apiPath

  constructor () {
    super()
    this.apiPath = this.appConfig.api.paths.elo
  }
}
